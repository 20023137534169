import axios from "axios";
import Moralis from "moralis";
import { EvmChain, EvmNft } from "@moralisweb3/evm-utils";
import BigNumber from "bignumber.js";

export interface PolacySummary {
  totalNFTNumber: number;
  totalWalletsNumber: number;
  totalVolume: string;
  floorPrice: string;
  plnCollectionPrice: number;
  plnFloorNFT: number;
}

export interface LeaderType {
  address: string;
  avatar: string;
  count: number;
  opensea: string;
  counter: number;
}

export interface MetadataAttribute {
  trait_type: string;
  value: string;
}

export interface NFTMetadataJson {
  name: string;
  description: string;
  image: string;
  dna: string;
  edition: number;
  date: number;
  attributes: MetadataAttribute[];
  compiler: string;
}

export class NFTInfo {
  public static getOwnersDetails = async (): Promise<LeaderType[] | Error> => {
    try {
      const url = `${process.env.API_BASE_URL}/`;

      const response = await fetch(url);
      const responseJson = (await response.json()) as any[];

      let polacy = [];
      let counter = 1;
      for (let polak of responseJson) {
        let newOne: LeaderType = {
          address: polak[0],
          avatar: polak[1]?.avatar,
          count: polak[1].count,
          opensea: polak[1].opensea,
          counter,
        };
        polacy.push(newOne);
        counter++;
      }

      return polacy as LeaderType[];
    } catch (e) {
      console.error(e);
      // make sure we return error, if something went wrong we'd like to retry fetching again in a few
      return e as Error;
    }
  };

  public static getPolacySummary = async (): Promise<PolacySummary> => {
    let summary: PolacySummary = {
      totalNFTNumber: 0,
      totalWalletsNumber: 0,
      totalVolume: "",
      floorPrice: "",
      plnCollectionPrice: 0,
      plnFloorNFT: 0,
    };
    try {
      const response = await fetch(`${process.env.API_BASE_URL}/data/summary`);
      const summary = await response.json();
      return summary;
    } catch (err) {
      console.error(err);
    }
    return summary;
  };

  // todo: move
  public static getUserNFTsImagesURIs = async (
    evmLoggedAddress: string
  ): Promise<NFTMetadataJson[]> => {
    let nftsList: NFTMetadataJson[] = [];
    try {
      const url = `${process.env.API_BASE_URL}/nfts/${evmLoggedAddress}`;

      const response = await fetch(url);
      const responseJson = (await response.json()) as {
        nfts: NFTMetadataJson[];
      };

      return responseJson.nfts;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
}
