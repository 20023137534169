import * as React from "react";
import type { HeadFC } from "gatsby";

import { useWeb3Provider, Web3Providers } from "../contexts/AppContext";

import awesomeHat from "../images/awesome-hat.svg";

import { GoHomeButton } from "../components/Items/Buttons/Buttons";
import { NFTInfo, NFTMetadataJson } from "../services/NFTInfo";
import { PolakImageBox } from "../components/IndexSections/LoggedIn/MyPolacySection/MyPolacySection";

import { DashboardNavbar } from "../components/DashboardNavbar/DashboardNavbar";
import { Loader } from "../components/Loader";
import { OPENSEA_API_NFT_BASE_URL, trimAddress } from "../utils";
import { SEO } from "../components/SEO";
import { Leads, PolacyLead } from "../services/Leads";

import linkedinLogo from "../images/linkedin.png";
import emailLogo from "../images/mail.png";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
interface IPolacyLead {
  data: PolacyLead;
}

export const PolesNotFound = () => (
  <div className="owned-poles__notfound">
    <h2>
      Co tu się stało? <br />
      Nie znaleźliśmy ThePolaka na Twoim walletcie 👀 🤔
    </h2>
    <a
      href="https://opensea.io/collection/thepolacy"
      rel="noopener noreferrer"
      target="_blank"
      className="yellow-btn my-poles-section__button theshadow btn-clicked "
    >
      Nie bądź cebula... znajdź swojego przyjaciela drogi i dołącz do elitarnej
      ekipy :)
      <br />
      <br />
      Odblokuj dostęp do strony dołączając do naszej armii
      <br />
      <br />
      Obiecujemy, że będzie fajnie 🫡
    </a>
  </div>
);

const IndexPage = ({ serverData }: { serverData: PolacyLead[] }) => {
  return (
    <Web3Providers>
      <IndexPageComponent serverData={serverData} />
    </Web3Providers>
  );
};

const IndexPageComponent = ({ serverData }: { serverData: PolacyLead[] }) => {
  const web3Provider = useWeb3Provider();
  const [nfts, setNFTs] = React.useState<NFTMetadataJson[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (web3Provider.accountDetails?.address) {
      // use localstorage not to ask to many times about nfts per user (should ask only once when user logs in)
      let ownedNFTs = localStorage.getItem("nfts");
      // console.log(ownedNFTs)
      if (ownedNFTs == undefined || ownedNFTs == null) {
        // console.log("fetching");
        setIsLoading(true);
        NFTInfo.getUserNFTsImagesURIs(
          web3Provider.accountDetails?.address
        ).then(res => {
          localStorage.setItem("nfts", JSON.stringify(res));
          setNFTs(res);
          setIsLoading(false);
        });
      } else {
        const nftsFromCache = JSON.parse(ownedNFTs);
        setNFTs(nftsFromCache);
        setIsLoading(false);
      }
    }
  }, [web3Provider.accountDetails?.address]);

  return (
    <Web3Providers>
      <main className="owned-poles-page">
        {web3Provider.accountDetails?.address ? (
          <DashboardNavbar />
        ) : (
          <h1>Zaloguj się, aby zobaczyć co mogą zaoferować Ci thePolacy</h1>
        )}

        <section className="owned-poles__section">
          <img
            className={"owned-poles__image"}
            src={awesomeHat}
            alt={"Polski kapelusz :)"}
          />

          <h1 className="owned-poles__title">Rejestr Sławnych ThePolaków</h1>
          {nfts.length > 0 && (
            <p className="owned-poles__description">
              Odkryj Możliwości, Wspieraj Talenty: Twoja Brama do Współpracy i
              Innowacji w Społeczności ThePolacy.
            </p>
          )}
          <div
            style={{ overflowX: "auto", padding: "1rem" }}
            className="polacyleads"
          >
            {isLoading && <Loader />}
            {!isLoading && nfts.length > 0 && (
              <>
                <table className="polacyleads__table">
                  <thead>
                    <tr>
                      <th>ThePolak</th>

                      {/* <th>Opis usług</th> */}
                      <th>Profesja</th>
                      <th>Usługi</th>
                      {/* 
                        <th>Contact Links</th>
                        <th>LinkedIn URL</th> */}

                      <th>Realizowane projekty</th>
                      {/* <th>Adres portfela</th> */}
                      {/* <th>Email</th> */}
                      <th>Portfolio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {serverData.map((polak, index) => {
                      return (
                        <tr className="theshadow radius">
                          <td style={{ maxWidth: "100px" }}>
                            <strong>{polak.name}</strong>
                            <div
                              style={{ display: "flex", padding: "1rem 0 0 0" }}
                            >
                              {polak.linkedinUrl !== undefined ? (
                                <a
                                  href={polak.linkedinUrl}
                                  target="_blank"
                                  rel="noopener"
                                  style={{ margin: "0 1rem" }}
                                >
                                  <img
                                    style={{ maxWidth: "25px" }}
                                    src={linkedinLogo}
                                    alt="linkedin logo"
                                  />
                                </a>
                              ) : null}
                              {polak.email !== undefined ? (
                                <a
                                  href={`mailto:${polak.email}`}
                                  target="_blank"
                                  rel="noopener"
                                >
                                  <img
                                    style={{ maxWidth: "25px" }}
                                    src={emailLogo}
                                    alt="email logo"
                                  />
                                </a>
                              ) : null}
                            </div>
                          </td>
                          <td>{polak.professionalDomain}</td>
                          {/* <td>{polak.descriptionOfService}</td> */}
                          <td>{polak.services}</td>
                          <td>{polak.currentProjects}</td>
                          {/* <td>{polak.contactLinks}</td> */}
                          {/* <td>{polak.linkedinUrl}</td> */}
                          {/* <td>{polak.walletAddress}</td> */}
                          {/* <td>{polak.email}</td> */}
                          <td style={{ maxWidth: "100px" }}>
                            <a href={polak.portfolio} target="_blank">
                              {polak.portfolio}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}

            {!isLoading &&
            nfts.length === 0 &&
            web3Provider.accountDetails?.address ? (
              <PolesNotFound />
            ) : null}
          </div>

          <GoHomeButton />
        </section>
      </main>
    </Web3Providers>
  );
};

export default IndexPage;

// export const Head: HeadFC = () => <title>Sąsiedzi</title>;
export const Head: HeadFC = () => <SEO subtitle="Usługi ThePolaków" />;

export async function getServerData() {
  try {
    const response = await Leads.getLeads();
    if (response instanceof Error) throw response;
    return { props: response };
  } catch (err) {
    console.error(err);
    return {};
  }
}
